import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { AxiosResponse } from 'axios';
import clevertap from 'clevertap-web-sdk';

import './i18n';

import { AccountModel } from './models/Account';
import { reactPlugin } from './services/ApplicationInsightsService';
import { interceptMixpanelRequests } from './utility/api';
import { loadToltScript } from './utility/tolt';
import App from './App';

import './index.css';

declare global {
  interface Window {
    popupCallback: Promise<AxiosResponse<AccountModel> | null | undefined>;
    Intercom: (
      value: string,
      data?:
        | {
            app_id: string;
            name?: string;
            email?: string;
            user_id?: string;
            user_hash?: string;
          }
        | (() => void)
    ) => void;
    intercomSettings: {
      app_id: string;
      alignment: string;
      horizontal_padding: number;
      vertical_padding: number;
      custom_launcher_selector: string;
    };
    tolt_referral: string | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tolt: any;
  }
}

const APP_ID = import.meta.env.VITE_CLEVERTAP_APP_ID;
clevertap.init(APP_ID);
clevertap.spa = true;
interceptMixpanelRequests();
loadToltScript();

if (navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome')) {
  document.body.classList.add('safari');
} else if (navigator.userAgent.includes('Chrome')) {
  document.body.classList.add('chromium');
}

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('root') as HTMLElement;
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <AppInsightsContext.Provider value={reactPlugin}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AppInsightsContext.Provider>
    </React.StrictMode>
  );
});
