export enum InsightsTabs {
  SOCIALS = 'socials',
  MUSIC = 'music',
}

export enum SocialPlatform {
  OVERVIEW = 'overview',
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  TIKTOK = 'tiktok',
  TWITTER = 'twitter',
}

export enum MusicPlatform {
  OVERVIEW = 'overview',
  SPOTIFY = 'spotify',
  YOUTUBE = 'youtube',
  AMAZON = 'amazon',
  APPLE = 'apple',
  SOUNDCLOUD = 'soundcloud',
  DEEZER = 'deezer',
}

export enum OnboardingSteps {
  PRE_SCREEN,
  USER,
  ARTIST,
  CHOOSE_TRACK,
  RELEASE_DETAILS,
  RELEASE_DATE,
  LOGIN,
  SIGN_UP,
  CREATE_ACCOUNT,
  RELEASE_TYPE_CHOICE,
  BOOST_RELEASE_TRACK_PAGE,
}

export enum PromoOptions {
  BOOST,
  NEW,
  NONE,
}

export enum ACTIONS {
  INIT = 'init',
  START = 'start',
  STOP = 'stop',
  RESET = 'reset',
  PREV = 'prev',
  NEXT = 'next',
  GO = 'go',
  CLOSE = 'close',
  SKIP = 'skip',
  UPDATE = 'update',
}

export enum EVENTS {
  TOUR_START = 'tour:start',
  STEP_BEFORE = 'step:before',
  BEACON = 'beacon',
  TOOLTIP = 'tooltip',
  STEP_AFTER = 'step:after',
  TOUR_END = 'tour:end',
  TOUR_STATUS = 'tour:status',
  TARGET_NOT_FOUND = 'error:target_not_found',
  ERROR = 'error',
}

export enum LIFECYCLE {
  INIT = 'init',
  READY = 'ready',
  BEACON = 'beacon',
  TOOLTIP = 'tooltip',
  COMPLETE = 'complete',
  ERROR = 'error',
}

export enum STATUS {
  IDLE = 'idle',
  READY = 'ready',
  WAITING = 'waiting',
  RUNNING = 'running',
  PAUSED = 'paused',
  SKIPPED = 'skipped',
  FINISHED = 'finished',
  ERROR = 'error',
}

export enum VIEW_STATE {
  UNSEEN = 'unseen',
  VIEWED = 'viewed',
  SKIPPED = 'skipped',
}

export enum PromoteFlowQueryParams {
  FAN_HUB = 'fanHubFlowOpen',
  META_ADS = 'metaAdsFlowOpen',
  TIKTOK_ADS = 'tikTokAdsFlowOpen',
  PLAYLISTING = 'playlistingFlowOpen',
  RELEASE_CYCLES = 'releaseCycleFlow',
  SUBSCRIPTION = 'subscription',
  TODO_GOAL = 'todoGoal',
}
